import { Component, OnInit } from '@angular/core';
import { BitrixRestAPIService } from '@core/services';

@Component({
  selector: 'wg-user-not-found',
  template: `
  <div class="container-fluid">
    <div class="row">
        <div class="col text-center">
          <div class="about-block">
            <div class="dev-logo">
              <img src="assets/logo_w300.png">
            </div>
            <div class="dev-contacts-info">
              <div><b>Сайт:</b><a href="http://piksis.ru/" target="_blank"> piksis.ru</a></div>
              <div>Поддержка приложения осуществляется по электронной почте: <b>support@piksis.ru</b></div>
            </div>
            <button type="button" class="btn btn-primary" [routerLink]="['/pages', 'profile']" >На главную</button>&nbsp;&nbsp;
            <button class="btn btn-outline-danger ml-20" [routerLink]="['/cms', 'clear']" type="button">Удалить демо данные</button>
          </div>

        </div>
    </div>
  </div>
  <button type="button" class="btn btn-primary" style="display: none;" (click)="update()" >Update</button>
  `,
  styles: [`
  .about-block { padding-top: 150px; width: 325px; margin: auto;}
  .dev-contacts-info { margin: 20px 0; text-align: left;}
  `]
})
export class DevComponent implements OnInit {

  constructor(
    private api: BitrixRestAPIService
  ) { }

  update() {
    this.updeteOrder();
    this.updeteHistory();
  }

  updeteOrder() {
    console.log('updeteOrder');
    let params = {
      ENTITY: 'order',
      PROPERTY: 'COMMENT',
      NAME: 'Comment for nomination',
			TYPE: 'S'
    };
    let s = this.api.callMethod('entity.item.property.add', params).subscribe((res)=>{
      console.log('order', res);
      s.unsubscribe();
    });
  }

  updeteHistory() {
    console.log('updeteHistory');
    let params = {
      ENTITY: 'history',
      PROPERTY: 'COMMENT',
      NAME: 'Comment for nomination',
			TYPE: 'S'
    };
    let s = this.api.callMethod('entity.item.property.add', params).subscribe((res)=>{
      console.log('history', res);
      s.unsubscribe();
    });
  }

  ngOnInit() {
  }

}
