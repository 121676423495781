import { Injectable } from '@angular/core';
import { BitrixRestAPIService } from './bitrix-rest-api.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { share, filter, map } from 'rxjs/operators';
import { WgResult } from "./../models/result";

@Injectable({
  providedIn: 'root'
})
export class CoinsAccountService {

  private _coinsAccountStorage: string = 'coins_account';
  private _userStorage: string = 'user';
  private _result: BehaviorSubject<WgResult> = new BehaviorSubject(null);

  private _userId: number = 0;
  private _coins: number = 0;

  constructor(
    private api: BitrixRestAPIService
  ) {
  }

  _addCoins(idAccount, summ) {
    let s = this.api.callMethod('entity.item.update', {
      ENTITY: this._coinsAccountStorage,
      ID: idAccount,
      PROPERTY_VALUES: {
        BALANCE: summ
      },
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{

      let msg = `Счет пользователя с id ${this._userId} успешно обновлен`;
      let messages = {
        admin: `Монеты успешно присвоены`,
        user: `Вам присвоено ${this._coins} монет`,
        history: `Присвоено ${this._coins} монет`,
        action: ''
      };
      if(!res) {
        msg = `Счет пользователя с id ${this._userId} не обновлен`;
        messages = {
          admin: `Монеты не присвоены`,
          user: ``,
          history: ``,
          action: ''
        };
      }

      this._result.next({
        succes: res,
        message: msg,
        messages: messages,
        type: 'coins',
        value: this._coins,
      });
      s.unsubscribe();
    });
  }

  _createUserCoinsAccount(userApp) {
    let s = this.api.callMethod('entity.item.add', {
      ENTITY: this._coinsAccountStorage,
      NAME: `${userApp.NAME} ${this._userId}`,
      PROPERTY_VALUES: {
        ID_USER: userApp.ID,
        ID_USER_B24: userApp.PROPERTY_VALUES.ID_USER_B24,
        BALANCE: 0
      },
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((id)=>{
      this._addCoins(id, this._coins);
      s.unsubscribe();
    });
  }

  _getUserById() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._userStorage,
      FILTER: {ID: this._userId}
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
      if(res.length == 0) {
        this._result.next({
          succes: false,
          message: `Пользователь с id ${this._userId} не найден`
        });
      } else {
        this._createUserCoinsAccount(res[0]);
      }
      s.unsubscribe();
    });
  }

  _getUserCoinsAccount() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._coinsAccountStorage,
      FILTER: {PROPERTY_ID_USER: this._userId}
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
       if(res.length == 0) {
        this._getUserById();
       } else {
        this._addCoins(res[0].ID, parseInt(res[0].PROPERTY_VALUES.BALANCE) + this._coins);
       }
       s.unsubscribe();
    });
  }

  _clear() {
    this._result.next(null);
  }

  add(user: number, coins: number): Observable<WgResult> {

    this._clear();
    this._userId = user;
    this._coins = coins;

    this._getUserCoinsAccount();

    return this._result
            .pipe(
              filter(value => !!value),
              share()
            );
  }
}
