import { Injectable } from '@angular/core';
import { BitrixRestAPIService } from './bitrix-rest-api.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { share, filter, map } from 'rxjs/operators';
import { WgResult } from "./../models/result";

@Injectable({
  providedIn: 'root'
})
export class LevelService {

  private _levelStorage: string = 'level';
  private _userStorage: string = 'user';
  private _result: BehaviorSubject<WgResult> = new BehaviorSubject(null);

  private _userId: number = 0;
  private _level: number = 0;

  constructor(
    private api: BitrixRestAPIService
  ) {}

  _updateLevel(id, summ) {
    let s = this.api.callMethod('entity.item.update', {
      ENTITY: this._levelStorage,
      ID: id,
      PROPERTY_VALUES: {
        LEVEL: summ
      },
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{

      let msg = `Уровень пользователя с id ${this._userId} успешно обновлен`;
      let messages = {
        admin: `Уровень усешно обновлен`,
        user: `Ваш уровень увеличен на ${this._level}`,
        history: `Уровень увеличен на ${this._level}`,
        action: ''
      };
      if(!res) {
        msg = `Уровень пользователя с id ${this._userId} не обновлен`;
        messages = {
          admin: `Уровень не обновлен`,
          user: ``,
          history: ``,
          action: ''
        };
      }

      this._result.next({
        succes: res,
        message: msg,
        messages: messages,
        type: 'level',
        value: this._level,
      });
      s.unsubscribe();
    });
  }

  _createUserLevel(userApp) {
    let s = this.api.callMethod('entity.item.add', {
      ENTITY: this._levelStorage,
      NAME: `${userApp.NAME} ${this._userId}`,
      PROPERTY_VALUES: {
        ID_USER: userApp.ID,
        ID_USER_B24: userApp.PROPERTY_VALUES.ID_USER_B24,
        LEVEL: 0
      },
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((id)=>{
      this._updateLevel(id, this._level);
      s.unsubscribe();
    });
  }

  _getUserById() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._userStorage,
      FILTER: {ID: this._userId}
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
      if(res.length == 0) {
        this._result.next({
          succes: false,
          message: `Пользователь с id ${this._userId} не найден`
        });
      } else {
        this._createUserLevel(res[0]);
      }
      s.unsubscribe();
    });
  }

  _getUserCurrentLevel() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._levelStorage,
      FILTER: {PROPERTY_ID_USER: this._userId}
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
       if(res.length == 0) {
        this._getUserById();
       } else {
        this._updateLevel(res[0].ID, parseInt(res[0].PROPERTY_VALUES.LEVEL) + this._level);
       }
       s.unsubscribe();
    });
  }

  _clear() {
    this._result.next(null);
  }

  add(user: number, level: number): Observable<WgResult> {

    this._clear();
    this._userId = user;
    this._level = level;

    this._getUserCurrentLevel();

    return this._result
            .pipe(
              filter(value => !!value),
              share()
            );
  }
}
