import { Component, OnInit } from '@angular/core';
import { BitrixRestAPIService } from '@core/services';
import { WgUserService } from '@core/services';
import { WgUser } from './core/models/user';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  providers: []
})
export class AppComponent implements OnInit {
  title = 'wg';
  private _userStorage: string = 'user';

  constructor(
    private api: BitrixRestAPIService,
    private userService: WgUserService,
    private router: Router
  ) {
  }

  findUserInApp(user) {

    let batch = [];

    batch.push({
      method: 'entity.item.get',
      params: {
        ENTITY: this._userStorage,
        FILTER: {
          PROPERTY_ID_USER_B24: user.ID
        }
      }
    });

    batch.push({
        method: 'entity.item.get',
        params: {
          ENTITY: 'moderator',
          FILTER: {
            PROPERTY_ID_USER: user.ID
          }
        }
    });

    batch.push({
      method: 'entity.item.get',
      params: {
        ENTITY: 'settings',
        FILTER: {
          CODE: 'demo_removed'
        }
      }
  });

    let s = this.api.callBatch(batch).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{

      let u = res.result[0][0];
      let role = res.result[1][0];
      let demo_cleared = false;

      if(res.result[2] && res.result[2][0]) {
        if(res.result[2][0]['DETAIL_TEXT'] != '') {

          let demo = JSON.parse(res.result[2][0]['DETAIL_TEXT']);
          if(typeof demo == 'object' && demo.disabled_alert) {
            demo_cleared = demo.disabled_alert;
          }
        }
      }

      if(!u) {
        this.router.navigate(['/user-not-found']);
      } else {
        this.userService.addUser({
          id: parseInt(u.ID),
          id_b24: parseInt(u.PROPERTY_VALUES.ID_USER_B24),
          name: u.NAME,
          photo: encodeURI(u.PREVIEW_PICTURE),
          department: parseInt(u.PROPERTY_VALUES.DEPARTMENT),
          title_department: u.PROPERTY_VALUES.TITLE_DEPARTMENT,
          group_skill: parseInt(u.PROPERTY_VALUES.GROUP_SKILL),
          position: u.PROPERTY_VALUES.POSITION,
          role: role ? role.PROPERTY_VALUES.ROLE : '',
          demo_cleared: demo_cleared

        });

      }
    });
  }

  getUser() {
    this.api.callMethod('user.current',{})
      .pipe(
        map( ({ result }) => result )
      )
      .subscribe(response => {
        this.findUserInApp(response);
      });
  }

  resizeIframe() {
    /*this.api.resizeWindow(1554, 1200, (data)=>{
    });*/
  }

  ngOnInit() {
    this.api.onInit().subscribe( status => {
      if(status) {
        this.resizeIframe();
        this.getUser();
      }
    });
  }
}
