import { Injectable } from '@angular/core';
import { BitrixRestAPIService } from './bitrix-rest-api.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { share, filter, map } from 'rxjs/operators';
import { WgResult } from "./../models/result";
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LikeService {

  private _likeStorage: string = 'like_account';
  private _userStorage: string = 'user';
  private _result: BehaviorSubject<WgResult> = new BehaviorSubject(null);

  private _userId: number = 0;
  private _like: number = 0;
  private _count: number = 0;

  subscription: Subscription[] = [];

  constructor(
    private api: BitrixRestAPIService
  ) {}

  _updateLike(id, summ) {
    let s = this.api.callMethod('entity.item.update', {
      ENTITY: this._likeStorage,
      ID: id,
      PROPERTY_VALUES: {
        COUNT: summ
      },
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{

      let msg = `Нравится пользователя с id ${this._userId} успешно обновлен`;
      let messages = {
        admin: `Отметки "Эмодзи" успешно добавлены`,
        user: `Вам поставлена отметка "Эмодзи"`,
        history: `Поставлена отметка "Эмодзи"`,
        action: ''
      };
      if(!res) {
        msg = `Нравится пользователя с id ${this._userId} не обновлен`;
        messages = {
          admin: `Отметки "Эмодзи" не добавлены`,
          user: ``,
          history: ``,
          action: ''
        };
      }

      this._result.next({
        succes: res,
        message: msg,
        messages: messages,
        type: 'like',
        value: this._like,
      });
      this._clearSubscriptions();
    });
    this.subscription.push(s);
  }

  _createUserLike(userApp) {
    let s = this.api.callMethod('entity.item.add', {
      ENTITY: this._likeStorage,
      NAME: `${userApp.NAME} ${this._userId}`,
      PROPERTY_VALUES: {
        ID_USER: userApp.ID,
        ID_USER_B24: userApp.PROPERTY_VALUES.ID_USER_B24,
        ID_LIKE: this._like,
        COUNT: 0
      },
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((id)=>{
      this._updateLike(id, this._count);
    });
    this.subscription.push(s);
  }

  _getUserById() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._userStorage,
      FILTER: {ID: this._userId}
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
      if(res.length == 0) {
        this._result.next({
          succes: false,
          message: `Пользователь с id ${this._userId} не найден`
        });
        this._clearSubscriptions();
      } else {
        this._createUserLike(res[0]);
      }
    });
    this.subscription.push(s);
  }

  _getUserCurrentLike() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._likeStorage,
      FILTER: {
        PROPERTY_ID_USER: this._userId,
        PROPERTY_ID_LIKE: this._like
      }
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
       if(res.length == 0) {
        this._getUserById();
       } else {
        this._updateLike(res[0].ID, parseInt(res[0].PROPERTY_VALUES.COUNT) + this._count);
       }
    });
    this.subscription.push(s);
  }

  _clearSubscriptions() {
    this.subscription.forEach((item)=>{
      item.unsubscribe();
    });
    this.subscription = [];
    this._result.next(null);
  }

  add(user: number, like: number, count: number): Observable<WgResult> {

    this._userId = user;
    this._like = like;
    this._count = count;

    this._getUserCurrentLike();

    return this._result
            .pipe(
              filter(value => !!value),
              share()
            );
  }
}
