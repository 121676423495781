import { Injectable } from '@angular/core';
import { BitrixRestAPIService } from './bitrix-rest-api.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { share, filter, map } from 'rxjs/operators';
import { WgResult } from "./../models/result";

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  private _skillStorage: string = 'skill';
  private _userSkillStorage: string = 'user_skill';
  private _userStorage: string = 'user';
  private _result: BehaviorSubject<WgResult> = new BehaviorSubject(null);

  private _userId: number = 0;
  private _skillId: number = 0;
  private _countPoints: number = 0;
  private _idElementInStorage: number = 0;
  private lastLevel: number = null;

  private _skill: {id: number, name: string, points: number, actionGroup: number} = null;

  constructor(
    private api: BitrixRestAPIService
  ) {
  }

  _calculateCount(newLevel: number) {

    let count = 0;

    // если создан новый Навык
    if(this.lastLevel == null && newLevel > 0) {
      count = newLevel;
    }

    if(newLevel > this.lastLevel) {
      count = Math.abs(newLevel - this.lastLevel);
    }
    return count;
  }

  _updateUserSkillProgres(level, progress) {
    let s = this.api.callMethod('entity.item.update', {
      ENTITY: this._userSkillStorage,
      ID: this._idElementInStorage,
      PROPERTY_VALUES: {
        LEVEL: level,
        PROGRESS: progress
      },
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{

      let msg = `Навык ${this._skill.name} пользователя с id ${this._userId} успешно обновлен`;
      let messages = {
        admin: `Навык "${this._skill.name}" успешно обновлен`,
        user: `Ваш навык "${this._skill.name}" увеличен на ${this._countPoints}`,
        history: `Навык "${this._skill.name}" увеличен на ${this._countPoints}`,
        action: `За 100% выполение навыка "${this._skill.name}": `
      };
      if(!res) {
        msg = msg = `Навык ${this._skill.name} пользователя с id ${this._userId} не обновлен`;
        messages = {
          admin: `Навык "${this._skill.name}" не обновлен`,
          user: ``,
          history: ``,
          action: ''
        };
      }

      this._result.next({
        succes: res,
        message: msg,
        messages: messages,
        type: 'skill',
        value: this._countPoints,
        skillId: this._skillId,
        actionGroup: this._skill.actionGroup,
        count: this._calculateCount(level) // на сколько изменился навык т.е сколько раз навык прошел 100% отмеку
      });
      s.unsubscribe();
    });
  }

  _calculateProgress(level: number, progress: number) {
    let currentLevel = level;
    let currentProgress = progress + this._countPoints;

    while(currentProgress >= this._skill.points) {
      currentLevel++;
      currentProgress -= this._skill.points;
    }

    while(currentProgress < 0) {
      currentLevel--;
      currentProgress += this._skill.points;
    }

    this._updateUserSkillProgres(currentLevel, currentProgress);
  }

  /*
   * Create new skill for user
   *
   */
  _createUserSkill(userApp) {
    let s = this.api.callMethod('entity.item.add', {
      ENTITY: this._userSkillStorage,
      NAME: `${userApp.NAME} ${this._userId}`,
      PROPERTY_VALUES: {
        ID_USER: userApp.ID,
        ID_USER_B24: userApp.PROPERTY_VALUES.ID_USER_B24,
        ID_SKILL: this._skillId,
        LEVEL: 0,
        PROGRESS: 0
      },
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((id)=>{
      this._idElementInStorage = id;
      this.lastLevel = 0;
      this._calculateProgress(0, 0);
      s.unsubscribe();
    });
  }

  _getUserById() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._userStorage,
      FILTER: {ID: this._userId}
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
      if(res.length == 0) {
        this._result.next({
          succes: false,
          message: `Пользователь с id ${this._userId} не найден`
        });
      } else {
        this._createUserSkill(res[0]);
      }
      s.unsubscribe();
    });
  }

  _getCurrentUserSkill() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._userSkillStorage,
      FILTER: {
        PROPERTY_ID_USER: this._userId,
        PROPERTY_ID_SKILL: this._skillId
      }
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
       if(res.length == 0) { // create new skill for user
        this._getUserById();
       } else {
        this._idElementInStorage = res[0].ID; // ID element for future update
        this.lastLevel = parseInt(res[0].PROPERTY_VALUES.LEVEL);
        this._calculateProgress(parseInt(res[0].PROPERTY_VALUES.LEVEL), parseInt(res[0].PROPERTY_VALUES.PROGRESS));
       }
       s.unsubscribe();
    });
  }

  /*
   * Get info about skill
   *
   */
  _getSkill() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._skillStorage,
      FILTER: {
        ID: this._skillId
      }
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
       if(res.length == 0) {
        this._result.next({
          succes: false,
          message: `Навык с id ${this._userId} не найден`
        });
       } else {
        this._skill = {
          id: parseInt(res[0].ID),
          name: res[0].PROPERTY_VALUES.NAME,
          points: parseInt(res[0].PROPERTY_VALUES.POINTS),
          actionGroup: parseInt(res[0].PROPERTY_VALUES.ACTION)
        };
        this._getCurrentUserSkill();
       }
       s.unsubscribe();
    });
  }

  _clear() {
    this._result.next(null);
  }

  add(user: number, skill: number, points: number): Observable<WgResult> {

    this._clear();
    this._userId = user;
    this._skillId = skill;
    this._countPoints = points;

    this._getSkill();

    return this._result
            .pipe(
              filter(value => !!value),
              share()
            );
  }
}
