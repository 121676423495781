import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TurnService {

  private turn: any[] = [];

  constructor() { }

  add(items: any[]) {

    if(items.length > 0)
      items.forEach(item => {
        this.turn.push(item);
      });
    
  }

  get next (): any {
    return this.turn.shift();
  }

  clear(): void {
    this.turn = [];
  }
}
