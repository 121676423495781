import { Injectable } from '@angular/core';
import { BitrixRestAPIService } from './bitrix-rest-api.service';
import { SpinerService } from './spiner.service';
import { NotificatorService } from './notificator.service';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { share, filter } from 'rxjs/operators';
import { WgResult } from "./../models/result";

@Injectable({
  providedIn: 'root'
})
export class ClearDemoDataService {

    private _result: BehaviorSubject<WgResult> = new BehaviorSubject(null);
    private steps: Array<string> = [];

    constructor(
        private api: BitrixRestAPIService,
        private spiner: SpinerService,
        private notify: NotificatorService
    ) {
        this.steps = ['honor', 'skill', 'coins', 'level', 'save'];
    }

    nextStep() {
        let step = this.steps.shift();

        if(!step) {
            this._result.next({
              succes: true,
              message: 'Все демоданные удалены'
            });
            this._result.next(null);
            return;
        }

        switch(step) {
            case 'honor':
                this.clearHonors();
                break;
            case 'skill':
                this.clearSkills();
                break;
            case 'coins':
                this.clearCoins();
                break;
            case 'level':
                this.clearLevel();
                break;
            case 'save':
                this.saveSettings();
                break;

        }
    }

    clearStorage(entity: string, start: number, successMessage: string) {
        let params = {
            ENTITY: entity,
        };

        if(start > 0) {
            params['start'] = start;
        }

        this.spiner.show();
        let s = this.api.callMethod('entity.item.get', params).subscribe((res)=>{

            this.spiner.hide();

            let batch = [];

            res.result.forEach(item => {
                batch.push({
                  method: 'entity.item.delete',
                  params: {
                    ENTITY: entity,
                    ID: item.ID
                  }
              });
            });

            if(batch.length > 0) {
              this.spiner.show();
              let s_batch = this.api.callBatch(batch).subscribe((batchResult) => {
                this.spiner.hide();
                s_batch.unsubscribe();
                this.clearStorage(entity, 0, successMessage);
              });
            } else {
                this.notify.messageAdd({
                    type: 'success',
                    message: successMessage
                });
                this.nextStep();
            }

            s.unsubscribe();
        });
    }

    clearHonors() {
        this.clearStorage('user_honor', 0, 'Демо данные наград удалены.');
    }

    clearSkills() {
        this.clearStorage('user_skill', 0, 'Демо данные навыков удалены.');
    }

    clearLevel() {
        this.clearStorage('level', 0, 'Демо данные уровней удалены.');
    }

    clearCoins() {
        this.clearStorage('coins_account', 0, 'Демо данные монет удалены.');
    }

    saveSettings() {
        let params = {
            ENTITY: 'settings',
            NAME: 'Demo removed',
            CODE: 'demo_removed',
            DETAIL_TEXT: JSON.stringify({removed: true, disabled_alert: true})
        };
        let s = this.api.callMethod('entity.item.add', params).subscribe((res)=>{
            this.nextStep();
            s.unsubscribe();
        });
    }

    clear() {

        this.nextStep();

        return this._result
                .pipe(
                filter(value => !!value),
                share()
                );
  }

  _clear() {
    this._result.next(null);
  }

  disableAlert() {

    let params = {
        ENTITY: 'settings',
        NAME: 'Demo removed',
        CODE: 'demo_removed',
        DETAIL_TEXT: JSON.stringify({removed: false, disabled_alert: true})
    };
    this.spiner.show();
    let s = this.api.callMethod('entity.item.add', params).subscribe((res)=>{
      this._result.next({
        succes: true,
        message: 'Настройки успешно сохранены'
      });
      this._result.next(null);
      this.spiner.hide();
      s.unsubscribe();
    });

    return this._result
                .pipe(
                filter(value => !!value),
                share()
                );
  }
}
