import { Injectable } from '@angular/core';
import { BitrixRestAPIService } from './bitrix-rest-api.service';
import { share, filter, map } from 'rxjs/operators';
import { BehaviorSubject, of, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  private actionsStorage: string = 'action';
  private userId: number = null;
  private result: BehaviorSubject<any> = new BehaviorSubject(null);
  private groupIds: number[] = [];
  private subscription: Subscription[] = [];
  private actions = [];
  private skillsIds = [];
  private honorsIds = [];
  private skills = [];
  private honors = [];

  constructor(
    private api: BitrixRestAPIService
  ) {
  }

  _findSkillOrHonor(id: number, type: string) {
    switch(type) {
      case 'skill':
        return this.skills.filter(item => item.id == id)[0];
      case 'honor':
        return this.honors.filter(item => item.id == id)[0];
    }
  }

  _sendResult() {
    let actions = [];

    this.actions.forEach(item => {
      switch(item.type) {
        case 'skill':
          item.skill = this.skills.filter(val => val.id == item.skill)[0];
          if(item.skill) {
            actions.push(item);
          }
          break;
        case 'honor':
          item.honor = this.honors.filter(val => val.id == item.value)[0];
          if(item.honor) {
            actions.push(item);
          }
          break;
        default:
          actions.push(item);
      }
    });
    this.result.next(actions);
    this._clear();
  }

  _loadSkillsAndHonors(b: any[] = []) {
    let batch = b;

    if(b.length == 0) {
      if(this.skillsIds.length > 0)
        batch.push({
            method: 'entity.item.get',
            params: {
              ENTITY: 'skill',
              FILTER: {
                ID: this.skillsIds
              }
            }
        });

      if(this.honorsIds.length > 0)
        batch.push({
          method: 'entity.item.get',
          params: {
            ENTITY: 'honor',
            FILTER: {
              ID: this.honorsIds
            }
          }
        });
    }

    let s = this.api.callBatch(batch).subscribe((res) => {

      batch.forEach((item, index) => {

        if(item.params.ENTITY == 'skill') {
          res.result.result[index].forEach(el => {
            this.skills.push({
              id: el.ID,
              name: el.PROPERTY_VALUES.NAME
            });
          });
        }

        if(item.params.ENTITY == 'honor') {
          res.result.result[index].forEach(el => {
            this.honors.push({
              id: el.ID,
              name: el.PROPERTY_VALUES.NAME
            });
          });
        }

      });

      let empty = true;
      //TODO: Доделать
      /*for (let i in batch) {
        if (res.result.result[i].more()) {
          empty = false;
          batch[i].params.start = res.result.result[i].answer.next;
        } else delete batch[i];
      }*/

      if(!empty) {
        this._loadSkillsAndHonors(batch);
      } else {
        this._sendResult();
      }
    });
    this.subscription.push(s);
  }

  _getActions(start: number) {

    let params = {
      ENTITY: this.actionsStorage,
      FILTER: {
        PROPERTY_ID_GROUP: this.groupIds
      }
    }

    if(start > 0) {
      params['start'] = start;
    }

    let s = this.api.callMethod('entity.item.get', params).subscribe((res) => {

      res.result.forEach(item => {

        let type = item.PROPERTY_VALUES.TYPE;
        if(type == 'honor')
          this.honorsIds.push(item.PROPERTY_VALUES.VALUE);

        if(type == 'skill')
          this.skillsIds.push(item.PROPERTY_VALUES.ID_SKILL);


        this.actions.push({
          id: item.ID,
          type: type,
          group: item.PROPERTY_VALUES.ID_GROUP,
          value: item.PROPERTY_VALUES.VALUE,
          skill: item.PROPERTY_VALUES.ID_SKILL,
        });
      });


      if(!!res.next){
        this._getActions(res.next);
      } else {
        this._loadSkillsAndHonors();
      }
    });
    this.subscription.push(s);
  }

  getActionsInGroups(group: number[]) {

    if(group.length == 0)
      return of([]);

    this.groupIds = group;
    this._getActions(0);

    return this.result
            .pipe(
              filter(value => !!value),
              share()
            );
  }

  getActions(id: number) {
    return this.api.callMethod('entity.item.get', {
      ENTITY: this.actionsStorage,
      FILTER: {
        PROPERTY_ID_GROUP: id
      }
    }).pipe(
      map( ({ result }) => result )
    );
  }

  _clear() {
    this.subscription.forEach((item)=>{
      item.unsubscribe();
    });
    this.subscription = [];
    this.actions = [];
    this.skillsIds = [];
    this.skills = [];
    this.result.next(null);
  }
}
