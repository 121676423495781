import { Injectable, EventEmitter } from '@angular/core';
import { Settings } from './settings.service';
import { ResizeService } from './resize.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { share, filter } from 'rxjs/operators';
import { Message, Table } from '@core/models';

export class PaginatorService {

  _totalRows: number = 0;
  _currentPage: number = 1;
  _data: any[] = [];
  _next:number = 0;
  loadNextPage: EventEmitter<number> = new EventEmitter();

  constructor(
    private _settings: Settings,
    private _dataTable: Table,
    private _resize_service: ResizeService
  ) { }

  delRowInList(row) {
    this._data = this._data.filter(item => {
      return item.id != row.id;
    });
    this._totalRows--;
    this._dataTable.totalRows = this.totalRows;
    return this.getDepartmentInMemory();
  }

  getNextPage(page: number) {
    //if( page == this.currentPage ) return;

    this._currentPage = page;

    if(this._totalRows <= 50) {
      this.getDepartmentInMemory();
    } else {
      let topBorder = this._currentPage*this._settings.pageElemetnCount;
      if(topBorder > this._totalRows) topBorder = this._totalRows;

      if(topBorder > this._data.length && topBorder <= this._totalRows) this.loadNextPage.emit(this._next);
        else this.getDepartmentInMemory();
    }
  }

  getDepartmentInMemory() {
    let start = this._settings.pageElemetnCount*(this._currentPage-1);
    let end = this._settings.pageElemetnCount*this._currentPage;
    if(end > this._data.length) end = this._data.length;

    this._dataTable.body = this._data.slice(start, end);
    this._resize_service.resize();
  }

  clear() {
    this._totalRows = 0;
    this._currentPage = 1;
    this._data = [];
    this._next = 0;
  }

  get currentPage() {
    return this._currentPage;
  }

  set currentPage( val: number ) {
    this._currentPage = val;
  }

  get totalRows() {
    return this._totalRows;
  }

  set totalRows( val: number ) {
    this._totalRows = val;
  }

  get next() {
    return this._next;
  }

  set next( val: number ) {
    this._next = val;
  }

  get data() {
    return this._data;
  }

  set data( val: any[] ) {
    this._data = val;
  }

  add(val) {
    this._data.push(val);
  }
}
