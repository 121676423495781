import { Injectable } from '@angular/core';
import { BitrixRestAPIService } from './bitrix-rest-api.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { share, filter, map } from 'rxjs/operators';
import { WgResult } from "./../models/result";

@Injectable({
  providedIn: 'root'
})
export class HonorService {

  private _honorStorage: string = 'honor';
  private _userHonorStorage: string = 'user_honor';
  private _userStorage: string = 'user';
  private _result: BehaviorSubject<WgResult> = new BehaviorSubject(null);


  private _userId: number = 0;
  private _honorId: number = 0;
  private _honor: {id: number, name: string, actionGroup: number} = null;

  constructor(
    private api: BitrixRestAPIService
  ) {
  }

  _deleteHonor(id: number) {
    let s = this.api.callMethod('entity.item.delete', {
      ENTITY: this._userHonorStorage,
      ID: id,
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
      let msg = `Награда успешно удалена`;
      if(!res)
        msg = `Награда не удалена`

      this._result.next({
        succes: res,
        message: msg
      });
      s.unsubscribe();
    });
  }

  _addHonor(userApp) {
    let s = this.api.callMethod('entity.item.add', {
      ENTITY: this._userHonorStorage,
      NAME: `User: ${this._userId} - Honor: ${this._honorId}`,
      PROPERTY_VALUES: {
        ID_USER: userApp.ID,
        ID_USER_B24: userApp.PROPERTY_VALUES.ID_USER_B24,
        ID_HONOR: this._honorId,

      },
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
      let msg = `Награда ${this._honorId} пользователю с id ${this._userId} успешно присвоена`;
      let messages = {
        admin: `Награда "${this._honor.name}" успешно присвоена`,
        user: `Вам присвоена награда "${this._honor.name}"`,
        history: `Присвоена награда "${this._honor.name}"`,
        action: `За награду "${this._honor.name}": `
      };
      if(!res) {
        msg = `Награда "${this._honor.name}" пользователю с id ${this._userId} не присвоена`;
        messages = {
          admin: `Награда "${this._honor.name}" не присвоена`,
          user: ``,
          history: ``,
          action: ''
        };
      }

      this._result.next({
        succes: res,
        message: msg,
        messages: messages,
        type: 'honor',
        value: this._honorId,
        actionGroup: this._honor.actionGroup
      });
      s.unsubscribe();
    });
  }

  _getUserById() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._userStorage,
      FILTER: {ID: this._userId}
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
      if(res.length == 0) {
        this._result.next({
          succes: false,
          message: `Пользователь с id ${this._userId} не найден`
        });
      } else {
        this._addHonor(res[0]);
      }
      s.unsubscribe();
    });
  }

  deleteHoner(id: number): Observable<WgResult> {
    this._deleteHonor(id);
    return this._result
            .pipe(
              filter(value => !!value),
              share()
            );
  }

  /*
   * Get info about honor
   *
   */
  _getHonor() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._honorStorage,
      FILTER: {
        ID: this._honorId
      }
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
       if(res.length == 0) {
        this._result.next({
          succes: false,
          message: `Нагрда с id ${this._userId} не найдена`,
        });
       } else {
        this._honor = {
          id: parseInt(res[0].ID),
          name: res[0].PROPERTY_VALUES.NAME,
          actionGroup: parseInt(res[0].PROPERTY_VALUES.ACTION)
        };
        this._getUserById();
       }
       s.unsubscribe();
    });
  }

  _clear() {
    this._result.next(null);
  }

  add(user: number, honor: number): Observable<WgResult> {

    this._clear();
    this._userId = user;
    this._honorId = honor;

    this._getHonor();

    return this._result
            .pipe(
              filter(value => !!value),
              share()
            );
  }
}
