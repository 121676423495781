import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { share, filter, map } from 'rxjs/operators';
import { BitrixRestAPIService } from './bitrix-rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class SkillListService {

  private skillGroupStorage: string = 'skill_group';
  private skillGroupSkillStorage: string = 'sg_s';
  private skillStorage: string = 'skill';

  private skills: BehaviorSubject<any> = new BehaviorSubject(null);
  private ids: number[] = [];


  constructor(
    private api: BitrixRestAPIService
  ) { }

  _getSkills(start: number) {
    if(!(this.ids.length > 0)) {
      return this.skills.next({
        result: [],
        total: 0
      });
    }

    let params = {
      ENTITY: this.skillStorage,
      FILTER: {
        ID: this.ids
      }
    };

    if(start > 0) {
      params['start'] = start;
    }

    let s = this.api.callMethod('entity.item.get', params).subscribe((res)=>{
      this.skills.next(res);
      // TODO: more 50
      /*if(!!res.next){
        this._getSkills(res.next);
      }*/
      s.unsubscribe();
    });
  }


  _getIdsSkills(start: number, group: number) {
    let params = {
      ENTITY: this.skillGroupSkillStorage,
      FILTER: {
        PROPERTY_ID_GROUP: group
      }
    };

    if(start > 0) {
      params['start'] = start;
    }

    let s = this.api.callMethod('entity.item.get', params).subscribe((res)=>{
      res.result.forEach((item) => {
        this.ids.push(parseInt(item.PROPERTY_VALUES.ID_SKILL));
      });

      if(!!res.next){
        this._getIdsSkills(res.next, group);
      } else {
        this._getSkills(0);
      }
      s.unsubscribe();
    });
  }

  clear() {
    this.skills.next(null);
  }

  _clear() {
    this.skills.next(null);
  }

  getNextPage(next: number) {
    this._clear();
    this._getSkills(next);
  }

  getSkillsInGroup(group: number): Observable<any> {
    this._clear();
    this.ids = [];
    this._getIdsSkills(0, group);

    return this.skills
            .pipe(
              filter(value => !!value),
              share()
            );
  }
}
