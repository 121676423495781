import { Injectable } from '@angular/core';
import { CoinsAccountService } from './coins-account.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { share, filter, map } from 'rxjs/operators';
import { HonorService } from './honor.service';
import { LevelService } from './level.service';
import { SkillService } from './skill.service';
import { ActionService } from './action.service';
import { LikeService } from './like.service';
import { WgResult } from "./../models/result";
import { Action } from "./../models/action";


@Injectable({
  providedIn: 'root'
})
export class EntityManagerService {

  private _result: BehaviorSubject<WgResult> = new BehaviorSubject(null);
  private userId: number = null;
  private subscription: Subscription[] = [];

  constructor(
    private _coins: CoinsAccountService,
    private _honor: HonorService,
    private _level: LevelService,
    private _skill: SkillService,
    private _like: LikeService,
    private _action: ActionService
  ) { }

  applyAction(user: number, action: Action) {
      //this._clear();
      switch (action.type) {
        case 'coins':
          this.addCoins(user, action.value);
          break;
        case 'level':
          this.addLevel(user, action.value);
          break;
        case 'honor':
          this.addHonor(user, action.value);
          break;
        case 'skill':
          this.addSkill(user, action.id_skill, action.value);
          break;
      }
  }

  _getActions(id: number, count: number, result: WgResult) {

    let s = this._action.getActions(id).subscribe((res)=>{

      let actions:Action[] = [];

      res.forEach(item => {
        actions.push({
          id: parseInt(item.ID),
          name: item.NAME,
          type: item.PROPERTY_VALUES.TYPE,
          value: parseInt(item.PROPERTY_VALUES.VALUE),
          id_skill: parseInt(item.PROPERTY_VALUES.ID_SKILL),
        });
      });

      if(typeof count != 'undefined' && count > 0) {
        for(let i=0; i < count; i++) {
          actions.concat(actions);
        }
      }

      result.actions = actions;
      this._result.next(result);
      s.unsubscribe();
    });
  }

  addHonor(user: number, honor: number): Observable<WgResult> {
    this.userId = user;
    let s = this._honor.add(user, honor).subscribe(data => {

      let res = data;

      if(data.actionGroup)
        this._getActions(data.actionGroup, 0, res);
      else
        this._result.next(res);

      s.unsubscribe();
    });

    return this._result
            .pipe(
              filter(value => !!value),
              share()
            );
  }

  addSkill(user: number, skill: number, points: number): Observable<WgResult> {
    this.userId = user;
    let s = this._skill.add(user, skill, points).subscribe(data => {

      let res = data;

      if(data.actionGroup && data.count > 0)
        this._getActions(data.actionGroup, data.count, res);
      else
        this._result.next(res);

      s.unsubscribe();
    });

    return this._result
            .pipe(
              filter(value => !!value),
              share()
            );
  }

  addCoins(user: number, coins: number): Observable<WgResult> {
    this.userId = user;
    let s = this._coins.add(user, coins).subscribe(res => {
      this._result.next(res);
      s.unsubscribe();
    });

    return this._result
            .pipe(
              filter(value => !!value),
              share()
            );
  }

  addLevel(user: number, level: number): Observable<WgResult> {
    this.userId = user;
    let s = this._level.add(user, level).subscribe(res => {
      this._result.next(res);
      s.unsubscribe();
    });

    return this._result
            .pipe(
              filter(value => !!value),
              share()
            );
  }

  addLike(user: number, like: number, count: number): Observable<WgResult> {
    this.userId = user;
    let s = this._like.add(user, like, count).subscribe(res => {
      this._result.next(res);
      s.unsubscribe();
    });

    return this._result
            .pipe(
              filter(value => !!value),
              share()
            );
  }

  onChangeResultMessage () {
    this._clear();
    return this._result
      .pipe(
        filter(value => !!value),
        share()
      );
  }

  _clear() {
    this._result.next(null);
  }
  clear() {
    this._clear();
  }
}
