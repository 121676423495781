import { Injectable } from '@angular/core';
import { BitrixRestAPIService } from './bitrix-rest-api.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { share, filter } from 'rxjs/operators';
import { Message } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class NotificatorService {

  private message: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private api: BitrixRestAPIService
  ) { }

  sendUserNotify(id: number, message: string): Observable<any> {
    if(isNaN(id))
      return of({result: null});

    return this.api.callMethod('im.notify', {to: id, message: message, type: 'SYSTEM'});
  }

  messageAdd(mess: Message) {
    if(typeof mess.location == 'undefined')
      mess.location = 'bottom';

    this.message.next(mess);
  }

  clear() {
    this.message.next(null);
  }

  onMessage(): Observable<Message> {
    return this.message
            .pipe(
              filter(value => !!value),
              share()
            );
  }
}
