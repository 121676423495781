import { Injectable } from '@angular/core';
import { BitrixRestAPIService } from './bitrix-rest-api.service';
import { IHistoryData } from "./../models/i-history-data";

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private api: BitrixRestAPIService
  ) { }

  addTransaction(data: IHistoryData) {
    let params = {
      ENTITY: 'transaction',
      NAME: data.name,
      PROPERTY_VALUES: {
        AUTHOR: data.author,
        ID_USER: data.idUser,
        MESSAGE: data.message,
        TYPE: data.type,
        VALUE: data.value,
      },
    };

    return this.api.callMethod('entity.item.add', params);
  }
}
