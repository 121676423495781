import { Injectable } from '@angular/core';
import { BitrixRestAPIService } from './bitrix-rest-api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class LogerService {

  private transactionStorage: string = 'transaction';

  constructor(
    private api: BitrixRestAPIService
  ) { }

  add(author: number, user: number, message: string, type: string, value: string) {
    let s = this.api.callMethod('entity.item.add', {
      ENTITY: this.transactionStorage,

      NAME: `Author: ${author} - from: ${user}`,
      PROPERTY_VALUES: {
        AUTHOR: author,
        ID_USER: user,
        MESSAGE: message,
        TYPE: type,
        VALUE: value,
      },
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
      let msg = `Запись в журнал успешно добавлена`;
      if(!res)
        msg = `Запись в журнал не добавлена`

        s.unsubscribe();
    });
  }
}
